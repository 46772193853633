//登录注册相关服务
import axios from "axios";
import generateParseObject from "./common/parse/ParseObjectFactory";
const md5=require("blueimp-md5");

const Parse=window.Parse;
const adminMenu= require('../layout/adminMenu');
const teacherMenu= require('../layout/teacherMenu');
const studentMenu= require('../layout/studentMenu');

const UserObj = generateParseObject("v5_user");

//保存当前用户的全局变量
let gUser = {
    username: "未登录",
};

//从localStorage中回复上次登录的用户
if(window.localStorage["gUser"]){
    const savedUser=JSON.parse(window.localStorage["gUser"]);
    Object.assign(gUser,savedUser);
}

export default class User {
    //用户注册
    static async reg(username, password,showName) {
        await User.cleanStatus();//清除上一个用户残留的状态
        try{
            const user= await axios.post('/user/reg', {username, password,showName})
                .then(res=>res.data);
            return user;
        }catch (e) {
            const error=(e.response && e.response.data && e.response.data.error) || "注册失败";
            throw error;
        }

    }

    //用unionid取得username
    static async get_username_by_unionid(unionid){
        const user = await axios.get(`/user/get_username_by_unionid/${unionid}`)
            .then(res=>res.data);
        return user;
    }

    //用户绑定
    static async wechat_bind_user(username,unionid,access_token) {
        try{
            const user= await axios.post('/user/bind_username_and_unionid', {username,unionid,access_token})
                .then(res=>res.data);
            User.saveUserStatusToLocal(user);
        }catch (e) {
            const error=(e.response && e.response.data && e.response.data.error) || "绑定微信用户失败";
            throw error;
        }
    }

    //微信用户连接Parse User
    static async wechat_linkwith_parse(username,unionid,access_token){
        //连接Parse User
        try{
            const parseUser = new Parse.User();
            const myAuthData = {
                id: unionid,
                access_token
            };
            await  parseUser.linkWith('wechat_auth', { authData: myAuthData });
            console.log("连接Parse用户成功");
            console.log(Parse.User.current().toJSON());
        }catch (e) {
            const error=(e.response && e.response.data && e.response.data.error) || "连接Parse User失败";
            throw error;
        }
    }

    //微信用户用access_token登录本系统
    static async wechat_user_login(username, access_token){
        await User.cleanStatus();//清除上一个用户残留的状态
        const user= await axios.post('/user/wechat_user_login', {username, access_token})
            .then(res=>res.data);
        User.saveUserStatusToLocal(user);
    }

    static saveUserStatusToLocal(user) {
        gUser = user;//登录成功，把用户信息保存到gUser
        User.setExpire();//设置当前用户的过期时间
        window.localStorage["gUser"] = JSON.stringify(gUser);//保存到localStorage以便关闭浏览器时仍不丢失
    }

//用户登录
    static async login(username, password) {
        await User.cleanStatus();//清除上一个用户残留的状态
        const salt="lims_v5";
        const password_md5 = md5(password+salt);
        const user= await axios.post('/user/login', {username, password_md5})
            .then(res=>res.data);
        User.saveUserStatusToLocal(user);

        //连接Parse User
        const parseUser = new Parse.User();
        const myAuthData = {
            id: username,
            password_md5
        };
        await  parseUser.linkWith('lims_auth', { authData: myAuthData });
        // if(user.unionid&&user.unionid.length>0){
        //     const myWechatAuthData = {
        //         id: user.unionid,  // Required field. Used to uniquely identify the linked account.
        //     };
        //     await parseUser.linkWith('wechat_auth', { authData: myWechatAuthData });
        // }
        console.log("连接Parse用户成功");
        console.log(Parse.User.current().toJSON());
    }

    static logout(){
        gUser={
            username: "未登录",
        };
        delete window.localStorage["gUser"];
        window.location.hash = '/login';
    }

    //清除上一个用户残留的状态
    static async cleanStatus(){
        window.gResourceManagerStore=null;//清除资源管理器状态
        window.gModalSelectStore = null;//清除课程
        window.gModalDoubleSelectStore = null;//清除课程和教学班级
        if(Parse.User.current()){
            try{
                await Parse.User.logOut();//如果上一个用户没退出，先退出
            }catch (e) {
                console.error(e)
            }

        }
    }

    //设置过期时间，在路由切换时检测到过期跳到登录页面
    static setExpire(username) {
        const now = Date.now();
        const expire = now + 8 * 60 * 60 * 1000; //8小时
        // const expire=now+8*1000; //8秒测试
        gUser.expire = expire;
    }

    //用户登录是否过期
    static isExpired() {
        return (!gUser.expire || Date.now() > gUser.expire)
    }

    static getCurrentUserName() {
        return gUser.username;
    }
    static getCurrentUserId() {
        return gUser.objectId;
    }
    static isAdmin() {
        return gUser.isAdmin;
    }
    static isReaderAdmin() {
        return gUser.isReaderAdmin;
    }
    static isTeacher(){
        return gUser.isTeacher;
    }
    static isStudent(){
        return !gUser.isAdmin && !gUser.isTeacher && !gUser.isReaderAdmin;
    }
    static getCurrentUserIcon(){
        return gUser.iconUrl;
    }

    static getCurrentUserMenu(){
        if(gUser.isAdmin){
            return adminMenu
        }
        if(gUser.isTeacher){
            return teacherMenu
        }else return studentMenu
    }

    static getCurrentUserShowName() {
        return gUser.showName;
    }
    static getCurrentStudentNumber() {
        return gUser.studentNumber;
    }
    static getIsAssistant() {
        return gUser.isAssistant;
    }
    static getCurrentUser() {
        return gUser;
    }

    // 获取用户的签章信息
    static async getUserCustomIconInfo() {
        const userId = this.getCurrentUserId();
        const user = await UserObj.getOne(userId);
        gUser.iconUrl = user.iconUrl;
        if (user.iconUrl === undefined || user.iconUrl === "") {
            return undefined;
        }
        return {objectId: userId, url: user.iconUrl};
    }
}
