import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  withRouter
} from "react-router-dom";
import loadable from "@loadable/component";
import BasicLayout from "./layout/BasicLayout";
import UserService from "./services/User";
import Home from "./routes/Home";

import {ConfigProvider} from "antd";
// 由于 antd 组件的默认文案是因为，所以需要修改为中文
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');


const ChangeTracker = withRouter(({match, location, history}) => {
    console.log(location.pathname, location.state);
    if (location.pathname !== '/login'&&
        !location.pathname.startsWith('/report/')&&
        location.pathname !== '/register'&&
        location.pathname !== '/reset'&&
        location.pathname !== '/a/input'&&
        !location.pathname.startsWith('/s/invite') &&
        (location.pathname && !location.pathname.startsWith('/wechat_login_callback'))&&
        (location.pathname && !location.pathname.startsWith('/wechat_qrcode'))
    ) {
        if (UserService.isExpired()) {
            console.log("登录时间过期！");
            window.location.hash = '/login';
        }
    }
    return false;
});


const Loading=()=><div>加载中...</div>;
const NotMatch=()=><div>NotMatch</div>;

const Login = loadable(() => import("./routes/Login"), {fallback: <Loading /> });
const Logout = loadable(() => import("./routes/Logout"), {fallback: <Loading /> });
const WeChatLoginCallback = loadable(() => import("./routes/WeChatLoginCallback"), {fallback: <Loading/>});
const WeChatBindCallback = loadable(() => import("./routes/WeChatBindCallback"), {fallback: <Loading/>});
const WeChatQrcode = loadable(() => import("./routes/WeChatQrcode"), {fallback: <Loading/>});



// const Home=()=><div>Home</div>
const ChangeInfo = loadable(() => import("./routes/ChangeInfo"), {fallback:<Loading />});
// 修改密码页面
const ChangePassword = loadable(() => import("./routes/login/ChangePassword"),{fallback: <Loading />});
// 管理员
const Teachers = loadable(() => import("./routes/admin/Teachers"), {fallback: <Loading/>});
const Students = loadable(() => import("./routes/admin/Students"), {fallback: <Loading/>});
const Admins = loadable(() => import("./routes/admin/Admins"), {fallback: <Loading />});
const NameList = loadable(() => import("./routes/admin/NameList"), {fallback: <Loading/>});
const NameListInfo = loadable(() => import("./routes/admin/NameListInfo"), {fallback: <Loading/>});
const UserEdit = loadable(() => import("./routes/admin/UserEdit"), {fallback: <Loading/>});

// 物料
const AutoBorrowKinds = loadable(() => import("./routes/auto_borrow/Kinds"));
const AutoBorrowGoods = loadable(() => import("./routes/auto_borrow/Goods"));
const AutoBorrowImportGoods = loadable(() => import("./routes/auto_borrow/ImportGoods"));
const AutoBorrowImportKinds = loadable(() => import("./routes/auto_borrow/ImportKinds"));
const AutoBorrowBorrowGoods = loadable(() => import("./routes/auto_borrow/BorrowGoods"));
const AutoBorrowBorrowList = loadable(() => import("./routes/auto_borrow/BorrowList"));
const AutoBorrowKindGoods = loadable(() => import("./routes/auto_borrow/KindGoods"));
const AutoBorrowReturnToWarehouse = loadable(() => import("./routes/auto_borrow/ReturnToWarehouse"));
const AutoBorrowQueryRecords = loadable(() => import("./routes/auto_borrow/QueryRecords"));
const AutoBorrowFaultRecords = loadable(() => import("./routes/auto_borrow/FaultRecords"));
const AutoBorrowExpBoxRecords = loadable(() => import("./routes/auto_borrow/ExpBoxRecords"));

const AutoBorrowGetGoods = loadable(() => import("./routes/auto_borrow/GetGoods"));
const AutoBorrowBorrowTime = loadable(() => import("./routes/auto_borrow/BorrowTime"));
const AutoBorrowAudit = loadable(() => import("./routes/auto_borrow/Audit.jsx"));


// const AutoBorrowReturnGoods = loadable(() => import("./routes/auto_borrow/ReturnGoods"));
const WhiteList= loadable(() => import("./routes/auto_borrow/WhiteList"));
const WhiteListInfo= loadable(() => import("./routes/auto_borrow/WhiteListInfo"));



//操作日志列表
const Logs = loadable(() => import("./routes/admin/Logs"),{fallback: <Loading />});



export default  ()=>{
  return (
  <ConfigProvider locale={zhCN}>
    <Router>
        <ChangeTracker/>
        <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/wechat_login_callback/:code" component={WeChatLoginCallback}/>
            <Route exact path="/wechat_bind_callback/:code" component={WeChatBindCallback}/>
            <Route path="/wechat_qrcode/:deviceId" component={WeChatQrcode}/>
            <BasicLayout>
                <Route path={"/change_info"} component={ChangeInfo} />
                <Route path={'/change_password'} component={ChangePassword} />
                {/*管理员*/}
                <Route exact path="/a/teachers"><Teachers /></Route>
                <Route exact path="/a/students" component={Students}/>
                <Route exact path={'/a/admins'} component={Admins}/>
                <Route exact path="/a/name_list" component={NameList}/>
                <Route  path="/a/name_list_info/:id" component={NameListInfo}/>
                <Route path="/a/userEdit/:username" component={UserEdit}/>
                <Route path={'/a/logs'} component={Logs} />

                <Route path="/auto_borrow/kinds" component={AutoBorrowKinds}/>
                <Route path="/auto_borrow/kindGoods/:id" component={AutoBorrowKindGoods}/>
                <Route path="/auto_borrow/goods" component={AutoBorrowGoods}/>
                <Route path="/auto_borrow/import_goods" component={AutoBorrowImportGoods}/>
                <Route path="/auto_borrow/import_kinds" component={AutoBorrowImportKinds}/>
                <Route path="/auto_borrow/borrow_goods" component={AutoBorrowBorrowGoods}/>
                <Route path="/auto_borrow/borrow_list" component={AutoBorrowBorrowList}/>
                <Route path="/auto_borrow/returnToWarehouse" component={AutoBorrowReturnToWarehouse}/>
                <Route path="/auto_borrow/query_records" component={AutoBorrowQueryRecords}/>
                <Route path="/auto_borrow/fault_records" component={AutoBorrowFaultRecords}/>
                <Route path="/auto_borrow/exp_box_records" component={AutoBorrowExpBoxRecords}/>

                <Route path="/auto_borrow/get_goods" component={AutoBorrowGetGoods}/>
                <Route path="/auto_borrow/audit" component={AutoBorrowAudit}/>

                <Route path="/white_list" component={WhiteList}/>
                <Route path="/white_list_info/:id" component={WhiteListInfo}/>
                <Route path="/auto_borrow/borrow_time" component={AutoBorrowBorrowTime}/>



                <Route exact path="/"><Home /></Route>
            </BasicLayout>
            <Route path="/*" component={NotMatch}/>
          </Switch>
    </Router>
  </ConfigProvider>
  );
}