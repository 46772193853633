let menuData = [
    {
        "title": "用户",
        "key":"users",
        "children": [
            {
                "title": "管理员",
                "url": "/a/admins",
            },{
                "title": "教师",
                "url": "/a/teachers",
            },{
                "title": "用户",
                "url": "/a/students",
            },
            {
                "title": "名单/班级管理",
                "url": "/a/name_list"
            },
        ]
    },
    {
        "key": "auto_borrow",
        "title": "物料",
        "children": [
            {
                "title": "类目与库存",
                "url": "/auto_borrow/kinds"
            },
            {
                "title": "申购审批",
                "url": "/auto_borrow/audit"
            },
            {
                "title": "借用",
                "url": "/auto_borrow/borrow_goods"
            },
            {
                "title": "领用",
                "url": "/auto_borrow/get_goods"
            },
            {
                "title": "维护",
                "url": "/auto_borrow/fault_records"
            },
            {
                "title": "归还",
                "url": "/auto_borrow/returnToWarehouse"
            },
        ]
    },
    // {
    //     "key": "box_manage",
    //     "title": "权限",
    //     "children": [
    //         {
    //             "title": "白名单",
    //             "url": "/white_list"
    //         },
    //     ]
    // },
    // {
    //     "key": "ZhiBan",
    //     "title": "值班时间",
    //     "children": [
    //         {
    //             "title": "时间安排",
    //             "url": "/auto_borrow/borrow_time"
    //         }
    //         // {
    //         //     "title": "借还记录",
    //         //     "url": "/auto_borrow/borrow_goods"
    //         // },
    //     ]
    // },
    {
        "title": "统计与日志",
        "key":"logs",
        "children": [
            {
                "title": "流水表",
                "url": "/auto_borrow/query_records"
            },
            // {
            //     "title": "实验箱借还记录",
            //     "url": "/auto_borrow/exp_box_records"
            // },
            {
                "title": "操作日志",
                "url": "/a/logs"
            },
        ]
    },

];


module.exports = menuData;
